import type { ReactElement } from 'react'
import cc from 'classcat'

import SuggestedSearch, { ResultsList, SearchField } from '../../templateComponents/SuggestedSearch'
import FacetedSearchToolbarTop from '../../templateComponents/FacetedSearch/FacetedSearchToolbarTop'
import Link from '../../templateComponents/Link'

export default function FacetedSearch({
  t,
  searchData,
  onScrollIntoView,
  isBusy,
}: SearchThemeComponentProps): ReactElement {
  return (
    <section className="faceted-search">
      <FacetedSearchToolbarTop searchData={searchData} t={t} onScrollIntoView={onScrollIntoView} isBusy={isBusy} />

      {searchData.products.length > 0 && searchData.queryString && (
        <ul>
          {searchData.products.map((product) => (
            <li key={product.href} className="search-result">
              <Link to={product.href}>{product.name}</Link>
            </li>
          ))}
        </ul>
      )}

      {(searchData.products.length === 0 || !searchData.queryString) && (
        <div>
          <div className="search-no-result-form">
            <h2 className="search-no-result-form-headline">
              {t('components.productSearchComponent.resultsCard.noResultsState.title')}
            </h2>
            <SuggestedSearch>
              <div className="search-form">
                <SearchField
                  maxSuggestedItems={5}
                  placeholder={t('components.productSearchComponent.searchInputField.placeholder')}
                />
                <ResultsList
                  renderItems={(items) => (
                    <ul>
                      {items.map((item) => (
                        <li className={cc({ active: item.active })} key={item.productId}>
                          <Link to={item.href}>{item.name}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                />
              </div>
            </SuggestedSearch>
          </div>

          <div className="search-no-result-hint">
            <h3>{t('components.productSearchComponent.resultsCard.noResultsState.hintSection.title')}</h3>
            <ul>
              <li>{t('components.productSearchComponent.resultsCard.noResultsState.hintSection.proposalOne')}</li>
              <li>{t('components.productSearchComponent.resultsCard.noResultsState.hintSection.proposalTwo')}</li>
            </ul>
          </div>
        </div>
      )}
    </section>
  )
}
